import React from 'react';
import './css/App.css';
import DanceEventList from "./component/DanceEventList.jsx";
import FieldTrip from "./component/FieldTrip.jsx";
import Container from "@mui/material/Container";
import ReactGA from "react-ga4";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Link from "@mui/material/Link";
import About from "./component/About.jsx";
import Contact from "./component/Contact.jsx";
import Help from "./component/Help.jsx";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InfoIcon from '@mui/icons-material/Info';
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import InstagramIcon from '@mui/icons-material/Instagram';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import CloseIcon from '@mui/icons-material/Close';
import {GoogleLogin} from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useCookies } from 'react-cookie';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import UserContext from "./component/UserContext.jsx";
import { RSVP } from "./component/RSVP.jsx";
import SchoolIcon from '@mui/icons-material/School';
import TwoStepLessons from "./component/TwoStepLessons.jsx";
import {Snackbar, Dialog, Paper} from "@mui/material";
import ReleaseList from "./component/ReleaseList.jsx";
import DanceDayDetailed from "./component/DanceDayDetailed.jsx";
import Artist from "./component/Artist.jsx";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

ReactGA.initialize('G-P7FJTJXR7Z');

const api_host = process.env.REACT_APP_API_HOST;

export const App = () => {


    const [anchorEl, setAnchorEl] = React.useState(null);
    const [cookies, setCookie, removeCookie] = useCookies(['jti','name','empty']);
    const [snackBar, setSnackBar] = React.useState({open: false, message: ""})
    const [showLogin, setShowLogin] = React.useState(false)
    let user = null;
    let prevRSVPMap = window.localStorage.getItem("RSVPMap");
    if (prevRSVPMap == null) {
        prevRSVPMap = {};
    } else {
        prevRSVPMap = JSON.parse(prevRSVPMap);
    }
    let [RSVPMap, setRSVPMap ] = React.useState(prevRSVPMap);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        setAnchorEl(null);
        if (event === undefined) {
            return;
        }
        window.location = event.currentTarget.getAttribute("href");
    };

    const logout = () => {
        fetch(api_host + "/?action=sessionDestroy&jti=" + cookies.jti);
        destroySessionCookies();
        window.localStorage.clear();
        setRSVPMap({});
        setAnchorEl(null);
        setSnackBar({open: true, message: "Log out successful"})
    }

    const destroySessionCookies = () => {
        removeCookie('jti');
        removeCookie('name');
    }

    const createSession = async (token) => {
        const user = await fetch(api_host + "/?action=sessionCreate&JWTToken=" + token)
            .then(res => res.json())
            .then(res => {
                setCookie('jti', res.jti, {maxAge: 31104000});
                setCookie('name', res.name, {maxAge: 31104000});

                return res;
            });
        setSnackBar({open: true, message: "Log in successful"})
        const rsvpMap = await RSVP.load(user.jti);
        setRSVPMap(rsvpMap);
        setShowLogin(false);

    }

    const isLoggedIn = () => {
        return cookies.hasOwnProperty('jti') && cookies.jti !== undefined
    }

    const sessionGet = async (jti) => {
        if (jti === "undefined" || jti === undefined) {
            console.log("no session to get");
            return null;
        }

        let user = null;
        return await fetch(api_host + "/?action=sessionGet&jti=" + cookies.jti)
            .then(res => res.json())
            .then(res => {

                //if session is gone on server, destroy it here too
                if (!res.hasOwnProperty('jti')) {
                    destroySessionCookies();
                    return null;
                }

                //otherwise reset cookies, this may be unnecessary
                setCookie('jti', res.jti, {maxAge: 31104000});
                setCookie('name', res.name, {maxAge: 31104000});

                return res;
            });

    }

    React.useEffect(  () => {
        const init = async (user) => {
            user = await sessionGet(cookies.jti)
            if (user !== null) {
                 setRSVPMap( await RSVP.load(user.jti));
            }
        }
        init(user);
    }, [user])

    var dimensions = {};
    if (window.innerWidth < 600) {
        dimensions = {
            headerHeight: "135px",
            banner: "url(/logo-mobile.png)",
            width: "100%",
            stickyAt: "-70px"
        }
    } else {
        dimensions = {
            headerHeight: "305px",
            banner: "url(/logo-full.png)",
            width: "1080px",
            stickyAt: "-170px"
        }
    }

    var loggedIn = isLoggedIn();

    var loginCorner;
    if (cookies.hasOwnProperty('jti')) {
        loginCorner =
            <div>
                <div style={{textAlign: "center", padding: "10px"}}>
                    <Avatar style={{textAlign: "center", margin:"auto"}}>{cookies.name.substr(0,1).toUpperCase()}</Avatar>
                    {cookies.name}
                </div>
            </div>
    } else {
        loginCorner = <IconButton size={"small"} style={{color: "white"}} onClick={() => setShowLogin(true)}>Login <LoginIcon /></IconButton>
    }

    const loginDialog =  <Dialog open={showLogin} style={{width: "100vw", backgroundColor:"white", padding:"20px"}}>
            <Paper style={{backgroundColor: "white", padding: "20px"}}>
                <Button style={{color:"black", float:"right"}} onClick={(e) => {setShowLogin(false);e.stopPropagation();}}><CloseIcon />close</Button>
                <h4 style={{color: "darkgrey"}}>Sign in with Google</h4>
                <GoogleLogin
                    ux_mode={"popup"}
                    onSuccess={credentialResponse => createSession(credentialResponse.credential)}
                    onError={() => {
                        console.log('Login Failed');
                    }}
                />
                <h4 style={{color: "darkgrey"}}>Is this safe?</h4>
                <p style={{color: "black"}}>Yes, Google logins are safe to use. Using Google to sign in will share your name, email and profile pic with this app.</p>
                <p style={{color: "black"}}>Your information is only used to make this app function and will not be used for marketing purposes.</p>
            </Paper>
        </Dialog>


    return (
        <ThemeProvider theme={darkTheme}>
      <CssBaseline />
        <GoogleOAuthProvider clientId="78262272063-k29amnt556dhpfupeepf2ng795278e03.apps.googleusercontent.com">
          <UserContext.Provider value={{loggedIn: loggedIn, jti: cookies.jti, rsvpMap: RSVPMap, setRSVPMap: setRSVPMap, setSnackBar: setSnackBar}}>
              {loginDialog}
              <Snackbar
                  message={snackBar.message} open={snackBar.open}
                  autoHideDuration={2000}
                  anchorOrigin={{ vertical:"top", horizontal:"center" }}
                  onClose={() => setSnackBar({open: false, message:""})}
              />
            <Container style={{backgroundColor: "#393536", padding: 0, width: dimensions.width}}>

                 <div style={{position: "sticky", top: dimensions.stickyAt, backgroundColor: "#393536", zIndex: 100}}>
                     <div style={{position: "sticky", top: "0px", zIndex: "101"}}>

                         <div style={{float: "right", marginRight: "70px"}}>
                             <div style={{position: "absolute", right: "0px"}}>{loginCorner}</div>
                             <Button style={{position: "absolute", left: "0px"}}
                                     onClick={handleClick}
                                     id="navigation"
                                     aria-controls={open ? 'basic-menu' : undefined}
                                     aria-haspopup="true"
                                     aria-expanded={open ? 'true' : undefined}>
                                  <MenuIcon style={{color: "white"}} fontSize={"large"}/>
                             </Button>
                             <Menu

                                 id={"main-menu"}
                                 anchorEl={anchorEl}
                                 open={open}
                                 onClose={() => handleClose(undefined)}>
                                  {loggedIn && <MenuItem onClick={logout}><ListItemIcon><LogoutIcon /></ListItemIcon><ListItemText>Logout</ListItemText></MenuItem>}
                                 <Divider/>
                                 <MenuItem onClick={handleClose} href="/">
                                     <ListItemIcon>
                                         <CalendarTodayIcon fontSize={"small"}/>
                                     </ListItemIcon>
                                     <ListItemText>Calendar</ListItemText>
                                 </MenuItem>
                                 <MenuItem onClick={handleClose} href="/about">
                                     <ListItemIcon>
                                         <InfoIcon fontSize={"small"}/>
                                     </ListItemIcon>
                                     <ListItemText>About</ListItemText>
                                 </MenuItem>
                                 <MenuItem onClick={handleClose} href="/fieldtrip">
                                     <ListItemIcon>
                                         <AirportShuttleIcon fontSize={"small"}/>
                                     </ListItemIcon>
                                     <ListItemText>Field Trip</ListItemText>
                                 </MenuItem>
                                 <MenuItem onClick={handleClose} href="/twostep-lessons/">
                                     <ListItemIcon>
                                         <SchoolIcon fontSize={"small"}/>
                                     </ListItemIcon>
                                     <ListItemText>Two-Step  Lessons</ListItemText>
                                 </MenuItem>
                                 <MenuItem onClick={handleClose} href="/release/">
                                     <ListItemIcon>
                                         <SchoolIcon fontSize={"small"}/>
                                     </ListItemIcon>
                                     <ListItemText>Releases</ListItemText>
                                 </MenuItem>
                                 {/*<MenuItem onClick={handleClose} href="/contact">Contact</MenuItem>*/}
                             </Menu>
                         </div>
                     </div>
                     <div style={{
                         width: "100%",
                         height: dimensions.headerHeight,
                         overflow: "hidden",
                         backgroundImage: dimensions.banner,
                         backgroundRepeat: "no-repeat",
                         backgroundPosition: "top center"
                     }}>
                     </div>
                     <div style={{backgroundColor: "#393536", height: "30px", padding: "0 15px 0 15px"}}>
                         <IconButton href="https://www.instagram.com/austin2step"
                                     style={{float: "left", position: "relative", top: "-15px"}}>
                            <InstagramIcon fontSize="large"/>
                         </IconButton>
                         {/*<IconButton style={{float: "right", position: "relative", top: "-15px"}}*/}
                         {/*            onClick={handleClick}*/}
                         {/*            id="navigation"*/}
                         {/*            aria-controls={open ? 'basic-menu' : undefined}*/}
                         {/*            aria-haspopup="true"*/}
                         {/*            aria-expanded={open ? 'true' : undefined}>*/}
                         {/*    <MenuIcon fontSize={"large"}/>*/}
                         {/*</IconButton>*/}
                         {/*<Menu*/}
                         {/*    id={"menu"}*/}
                         {/*    anchorEl={anchorEl}*/}
                         {/*    open={open}*/}
                         {/*    onClose={handleClose}>*/}
                         {/*    <MenuItem onClick={handleClose} href="/">Calendar</MenuItem>*/}
                         {/*    <MenuItem onClick={handleClose} href="/about">About</MenuItem>*/}
                         {/*    /!*<MenuItem onClick={handleClose} href="/contact">Contact</MenuItem>*!/*/}
                         {/*    /!*<MenuItem onClick={handleClose} href="/release">Release</MenuItem>*!/*/}
                         {/*</Menu>*/}
                      </div>
                     {/*<div>{cookies.firstName}</div>*/}
                 </div>
                <div style={{padding: '0px 20px 20px 20px'}}>
                  <Router>
                    <Switch>
                        <Route path="/release" component={ReleaseList} />
                        <Route path="/contact" component={Contact}/>
                        <Route path="/about" component={About}/>
                        <Route path="/fieldtrip" component={FieldTrip}/>
                        <Route path="/twostep-lessons/" component={TwoStepLessons}/>
                        <Route path="/calendar/*" component={DanceDayDetailed}/>
                        <Route path="/artist/:bandKey" component={Artist}/>
                        {/*<Route path="/help" component={Help} />*/}
                        {/*    <Route path="/event/:id/edit" component={DanceEventForm} />*/}
                        {/*    <Route path="/event/:id" component={DanceEventDetail} />*/}
                        {/*    <Route path="/event" component={DanceEventList} />*/}
                        {/*    <Route path="/style/:id" component={DanceStyleDetail} />*/}
                        {/*    <Route path="/style" component={DanceStyleGrid} />*/}
                        {/*    <Route path="/venue/:id" component={DanceVenueDetail} />*/}
                        {/*    <Route path="/venue/:id/edit" component={DanceVenueForm} />*/}
                        {/*    <Route path="/venue" component={DanceVenueList} />*/}
                        <Route path="/">
                          <DanceEventList isLoggedIn={loggedIn} jti={cookies.jti}/>
                      </Route>
                    </Switch>
                  </Router>
                    <h6>Site Map</h6>
                    <div><Link href={"/about"}>About</Link></div>
                    <div><Link href={"/fieldtrip"}>Field Trip</Link></div>
                    <div><Link href={"/twostep-lessons"}>Two Step Lessons</Link></div>
                </div>
              </Container>
            </UserContext.Provider>
          </GoogleOAuthProvider>
        </ThemeProvider>
    );

}

export default App;
