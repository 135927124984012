import Grid from "@mui/material/Grid";
import DanceDay from "./DanceDay.jsx";
import React, {useState, useEffect, useContext} from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import cookie from "cookie";
import Alert from "@mui/material/Alert";
import {toYYYYMMDD} from "../date.js";
import StarIcon from '@mui/icons-material/Star';
import userContext from "./UserContext.jsx";
import Link from "@mui/material/Link";
import {IconButton, Typography} from "@mui/material";
import ForwardIcon from '@mui/icons-material/Forward';

const DanceEventList = (props) => {

    const user = useContext(userContext);
  const cookies = cookie.parse(document.cookie);
  var initialDanceable = false;
  var initialShowLessons = true;
  var initialMyEventsOnly = false;
  if (cookies.hasOwnProperty("danceableOnly") && cookies.danceableOnly === "1") {
      initialDanceable = true;
  }
  if (cookies.hasOwnProperty("showLessons") && cookies.showLessons === "0") {
    initialShowLessons = false;
  }
  if (cookies.hasOwnProperty("myEventsOnly") && cookies.myEventsOnly === "1") {
      initialMyEventsOnly = true;
  }
  const [events, setEvents] = useState([]);
  const [danceableOnly, setDanceableOnly] = useState(initialDanceable);
  const [showLessons, setShowLessons] = useState(initialShowLessons);
  const [myEventsOnly, setMyEventsOnly] = useState(initialMyEventsOnly);
  const [rsvpMap, setRSVP] = useState(false);

  const filterPast = (events) => {
      const dateStr = toYYYYMMDD(new Date());
      return events.filter((e) => e.date >= dateStr)
  }

    const filterEvents = (eventList, danceableOnly, showLessons, myEventsOnly, rsvp) => {

      document.cookie = "danceableOnly=" + (danceableOnly ? "1" : "0") + "; max-age=31104000";
      document.cookie = "showLessons=" + (showLessons ? "1" : "0") + "; max-age=31104000";
      document.cookie = "myEventsOnly=" + (myEventsOnly ? "1" : "0") + "; max-age=31104000";

      if (danceableOnly) {
          eventList = eventList.filter(e => e.danceable);
      }
      if (!showLessons) {
          eventList = eventList.filter(e => !(e.type == "lesson"))
      }
      if (myEventsOnly) {
          eventList = eventList.filter(e => user.rsvpMap.hasOwnProperty(e.date + "-" + e.venueKey))
      }

      return eventList;
  }

  const mapEvents = (events) => {

      if (!events) {
          return [];

      }
      const map = {};

      for (let e of events) {
          const{ venue, venueKey, band, type, danceable, date, time, tag, bandKey } = e;
          if (!map.hasOwnProperty(date)) {
              map[date] = {date: date, data: []};
          }
          if (!map[date].data.hasOwnProperty(venueKey)) {
              map[date].data[venueKey] = {venue: venue, venueKey: venueKey, data: []};
          }
          map[date].data[venueKey].data.push({time: time, band: band, type:type, danceable: danceable, tag: tag, bandKey: bandKey})
      }
      let output = [];
      for (let dateStr of Object.keys(map)) {
          let eventDay = {date: dateStr, data: []};
          for (let venueKey of Object.keys(map[dateStr].data)) {
              eventDay.data.push(map[dateStr].data[venueKey])
          }
          output.push(eventDay);
      }
      return output;
  }

    const getEvents = () => {
        fetch(`/events.json`)
            .then(res => res.json())
            .then(events => filterPast(events))
            .then(res => setEvents(res))
    }

  useEffect(() => {
    //ensure a refresh every hour
    setInterval(getEvents, 3600 * 1000);
    getEvents();
    // getRSVP();
  }, [])

    const danceableLabel = <strong><Link underline={"none"}>Dancer Favorites Only</Link></strong>;
    const lessonLabel = <strong>Show Lessons</strong>;
    const myEventsLabel = <strong>Only starred events <StarIcon style={{color: "yellow", position: "relative", top: "5px"}} /></strong>;

    const toggleDanceable = (e) => {
        setDanceableOnly(e.target.checked);
    }
    const toggleLessons = (e) => {
        setShowLessons(e.target.checked);
    }
    const toggleMyEventsOnly = (e) => {
        setMyEventsOnly(e.target.checked);
    }



    var allEvents = mapEvents(filterEvents(events, danceableOnly, showLessons, myEventsOnly, rsvpMap));

    let contents;
    if (events === null) {
        contents = <Alert style={{width: "100%"}}>Loading Events</Alert>
    } else if (!props.isLoggedIn && myEventsOnly) {
        contents = <Alert style={{width: "100%"}} variant={"filled"} severity={"error"}>You must be logged in to show starred events. Disable filter or log-in to continue.</Alert>
    } else {
        contents = allEvents.map((danceDay) => <Grid item key={danceDay.date} xs={12} style={{paddingBottom: "20px"}}><DanceDay rsvpMap={rsvpMap} key={danceableOnly.toString()} danceDay={danceDay} danceableOnly={danceableOnly} isLoggedIn={props.isLoggedIn}/></Grid>)
    }

    return <Grid container>
            {/*<Grid item key={"fieldtrip"} xs={12} style={{paddingBottom: "20px"}}>*/}
            {/*    <Alert variant={"filled"} severity={"info"} icon={false} width={1}>*/}
            {/*        <Grid container width={"100%"} width={1}>*/}
            {/*            <Grid item xs={12} md={6} style={{textAlign: "center"}}><Typography fontSize={"1.5rem"}>Field Trip on Friday! All Hat, No Cadillac at Coupland Dance Hall!</Typography></Grid>*/}
            {/*            <Grid item xs={12} md={6} style={{textAlign: "center", verticalAlign:"middle"}}><IconButton size={"small"} onClick={() => window.location ="/fieldtrip"}>Check it Out<ForwardIcon /></IconButton></Grid>*/}
            {/*        </Grid>*/}
            {/*    </Alert>*/}
            {/*</Grid>*/}
            <Grid item key={"danceable"} xs={12} style={{paddingBottom: "20px"}}>
                <Card>
                    <CardContent>
                        <FormControlLabel label={danceableLabel} control={<Switch color={"primary"} checked={danceableOnly} onChange={toggleDanceable} />} />
                        <FormControlLabel label={lessonLabel} control={<Switch color={"primary"} checked={showLessons} onChange={toggleLessons} />} />
                        <FormControlLabel label={myEventsLabel} control={<Switch color={"primary"} checked={myEventsOnly} onChange={toggleMyEventsOnly} />} />
                    </CardContent>
                </Card>
            </Grid>
            {contents}
          </Grid>
}

export default DanceEventList