import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {Helmet} from "react-helmet";

const About = () => {
    return <Grid container>
        <Grid item>
            <Helmet>
                <meta charSet="utf-8"/>
                <meta property="og:description" content="learn more about this site - dancers for dancers"/>
                <meta property="twitter:title" content={"About & Mission of Austin 2 step"}/>
                <meta property="twitter:description" content="learn more about this site - dancers for dancers"/>
                <title>About Austin 2 step</title>
                <meta property="description" content="learn more about this site - dancers for dancers" />
                <link rel="canonical" href="https://austin2step.com/about"/>
            </Helmet>
            <Card>
                <CardContent>
                    <Typography variant={"h6"}>About</Typography>
                    <p>Austin 2 Step is a tool for discovering all things two step in Austin, the honky tonk capitol of the world, including where dancer's favorite bands are playing, where lessons are happening and more.</p>

                    <Typography variant={"h6"}>Mission</Typography>
                    <p>This site was created to enable the growing Austin 2 community discover more opportunities to dance. Everyone knows where to reliably go on Tuesday and Saturday but so much goes undiscovered. As development progresses, this site should make it easier to find new dance opportunities. The current popular days are still cherished, the aim is not to diminish these nights but to grow others.</p>
                    <p>This target for this site is first and foremost for the dancers, however, us two steppers wouldn't have any of this amazing honky tonk life if it wasn't for the venues, musicians and dance instructors. With that in mind, I wish to provide a platform that is unbiased towards any of them. Ideally, the more opportunities that present themselves for dancers, the more they discover new music, venues and dancing, the more the community will grow and we can pack even more places.</p>

                    <Typography variant={"h6"}>Me</Typography>
                    <p>My name is Tim. I am a dance addict. Can't help it. I have always been a dancer, though now more than ever. I was even into breakdancing as a kid, though those skills have sadly faded to nothing.</p>

                    <p>I grew up in Texas, was exposed to honky Tonks at a young age, including the original Gilley's in Pasadena. I don't remember a whole lot, other than the place was huge, there always people dancing and I saw Charlie Daniels absolutely slay a fiddle. Dude was insane.</p>

                    <p>I would also dance traditional Texas Two Step from time to time as an adult at various places in the Houston area but the community just wasn't there. It was more a strictly pick up scene, which is cool too, but sometimes you just want to dance. Also the traditional live music honky-tonks were dropping like flies being replaced with "country clubs" with DJs and pop country. Without the live music, it just felt cold.</p>

                    <p>For many years I grew tired of country music, was an edgy punk rocker, a skater, an aspiring to be pro downhill mountain biker in Canada, kinda did it all.</p>

                    <p>So back to the dance story... I moved to Seattle in 2010 and that's where the puzzle pieces started to click. After discovering the two step community there and learning the first version of a more swingy two step (THANKS @outlawswing), I got bit by the bug. Soon I was dancing several nights a week, and besides part of the pandemic lockdown, that hasn't stopped.</p>

                    <p>I moved back to Texas in 2022, mainly because I desperately needed sunshine before I went completely insane but also I wanted to dance more than Seattle could support. Their dance community, while strong, didn't grow back to its pre-pandemic size sadly. Overall it was the right move. I danced almost every night for a year and a half here in Austin, have had a fantastic time and have met so many amazing people. I wouldn't trade it for anything.</p>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
}

export default About